import React from "react";
import Footer from "../footer/Footer";
import "./../header/Header.css";
import Main from "../main/Main";
import { reactLocalStorage } from "reactjs-localstorage";

class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			naturePhotosClicked: false,
			streetPhotosClicked: false,
			travelPhotosClicked: false,
			allPhotosClicked: true,
			menu: false,
			darkTheme: reactLocalStorage.getObject("theme") === true,
		};
	}

	componentDidMount = () => {
		const concernedElement = document.querySelector(".nav");
		const clickOutSide = document.querySelector(".menu-wrapper");

		document.addEventListener("mousedown", (event) => {
			if (
				!concernedElement.contains(event.target) &&
				!clickOutSide.contains(event.target)
			) {
				this.setState({
					menu: false,
				});
			}
		});
	};

	newArray = () => {
		if (this.state.naturePhotosClicked === true) {
			return "nature";
		} else if (this.state.streetPhotosClicked === true) {
			return "street";
		} else if (this.state.travelPhotosClicked === true) {
			return "travel";
		} else if (this.state.allPhotosClicked === true) {
			return "all";
		} else {
			return false;
		}
	};

	darkTheme() {
		const newState = !this.state.darkTheme;

		this.setState({
			darkTheme: newState,
		});

		reactLocalStorage.set("theme", newState);
	}

	toggleMenu() {
		if (this.state.menu === false) {
			this.setState({
				menu: true,
			});
		} else {
			this.setState({
				menu: false,
			});
		}
	}

	render() {
		const { darkTheme } = this.state;
		const themeClassContent = darkTheme ? "dark" : "light";

		if (themeClassContent === "light") {
			document.querySelector("html").classList.add("light");
			document.querySelector("html").classList.remove("dark");
		} else {
			document.querySelector("html").classList.add("dark");
			document.querySelector("html").classList.remove("light");
		}

		return (
			<React.Fragment>
				<div className="header">
					<div className="header-logo">
						<div className="auth">
							<h1>Photos by Jens Nordström</h1>
							<i className="fas fa-signature"></i>
						</div>
					</div>
					<div
						className={`menu-wrapper ${
							this.state.menu === true ? "menu-wrapper-active" : ""
						}`}
						onClick={() => {
							this.toggleMenu();
						}}
					>
						<i className="fas fa-cog"></i>
						<div className="menu-text">settings</div>
					</div>
					<div className={`nav ${this.state.menu === true ? "nav-view" : ""}`}>
						<div
							className={`allPhotos ${
								this.state.allPhotosClicked === true ? "menu-active" : ""
							}`}
							onClick={() =>
								this.setState({
									allPhotosClicked: true,
									naturePhotosClicked: false,
									travelPhotosClicked: false,
									streetPhotosClicked: false,
								})
							}
						>
							<span>All photos</span>
							<i className="fas fa-images"></i>
						</div>
						<div
							className={`naturePhotos ${
								this.state.naturePhotosClicked === true ? "menu-active" : ""
							}`}
							onClick={() =>
								this.setState({
									naturePhotosClicked: true,
									streetPhotosClicked: false,
									travelPhotosClicked: false,
									allPhotosClicked: false,
								})
							}
						>
							<span>Nature photos</span>
							<i className="fas fa-tree"></i>
						</div>
						<div
							className={`streetPhotos ${
								this.state.streetPhotosClicked === true ? "menu-active" : ""
							}`}
							onClick={() =>
								this.setState({
									naturePhotosClicked: false,
									streetPhotosClicked: true,
									travelPhotosClicked: false,
									allPhotosClicked: false,
								})
							}
						>
							<span>Travel & street photos</span>
							<i className="fas fa-road"></i>
						</div>
						<div className="theme" onClick={this.darkTheme.bind(this)}>
							<span>{`${
								themeClassContent === "dark" ? "Light theme" : "Dark theme"
							}`}</span>
							<i
								className={`${
									themeClassContent === "dark" ? "fas fa-sun" : "fas fa-moon"
								}`}
							></i>
						</div>
					</div>
				</div>
				<Main
					menuState={this.state.menu}
					newArray={this.newArray()}
					selectedImageIndex={parseInt(
						this.props.match.params.selectedImageIndex &&
							this.props.match.params.selectedImageIndex.replace(
								"photo/photo-",
								""
							)
					)}
				/>
				<Footer />
			</React.Fragment>
		);
	}
}

export default Index;
